import React, { useEffect, useState } from 'react';
import Home from './components/Home';
import NewHome from './components/NewHome';
import './App.css';
import { Route, useLocation } from "react-router-dom";
import { auth } from './services/firebase';
import { loginUsuario, cerrarSesion, loginGoogle, checkSession, redirecTo, getSessionCookie } from './services/auth.js';
import Spinner from './components/Spinner'

import { useHistory } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { SnackbarProvider, useSnackbar } from 'notistack';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
library.add(fab)
function App() {
  const providerRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [confirmar, setConfirmar] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState("");
  const [accessToken, setAccessToken] = useState(null);

  let history = useHistory();
  let query = useQuery();
  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      if (user) {
        // console.log("force log con use 107", query.get("forceSesion"));
        if (!query.get("forceSesion")) {
          // console.log("procedimeinto para forzar cierre 109");
          try {
            const usuario = auth.currentUser;
            await getSessionCookie();
            // console.log("into trye 113");
            setUsuario(usuario);
            setLoading(false)
            //this.setState({ confirmar: true, usuario: usuario, loading: false });
          }
          catch (error) {
            console.log("error");
            //this.setState({ confirmar: false });
            console.log(error);
          }
        } else {
          // console.log("force de ")
          query.delete("forceSesion")
          let origin = query.get("origin");
          let scope = query.get("scope");
          let url = '/?origin=' + origin;
          if (scope) url = url + '&scope=' + scope;
          history.push(url);

          setLoading(false)

          await cerrarSesion();
        }

      } else {
        await checkSession()
          .catch(function (error) {
            console.log(error);
          });
        setLoading(false);
        setUsuario("");
        if (query.get("forceSesion")) {
          query.delete("forceSesion");
          let origin = query.get("origin");
          let scope = query.get("scope");
          let url = '/?origin=' + origin;
          if (scope) url = url + '&scope=' + scope;
          history.push(url);
        }
      }
    });
  }, [])

  const handleLogout = async () => {
    try {
      await cerrarSesion();
      setConfirmar(false);
      setUsuario("");
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = field => e => {

    // this.setState({
    //   ...this.state,
    //   form: {
    //     ...this.state.form,
    //     [field]: e.target.value,
    //   }
    // })
  }
  const handleSubmit = async values => {
    // console.log(values)

    try {
      setLoading(true);
      await loginUsuario(values.email, values.password)
        .then(res => {
          const origin = query.get("origin");
          // console.log(res);
          setTimeout(() => {
            setLoading(false);
          }, 15000)

          // redirecTo(origin);
        })
        .catch((error) => {
          setLoading(false);
          if (error.status === 401) {
            console.log("Invalid Login");
          } else {
            enqueueSnackbar("Email o Contraseña Incorrecta", {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            })
            console.log(error);
          }
        });
      setMensaje("Inició Sesión de manera correcta");
      //this.setState({ ...this.state, show: false, mostrarMensaje: true, mensaje: "Inició Sesión de manera correcta" });
    } catch (error) {
      console.log("imprimiendo error", error.message);
      setMensaje("Email o Contraseña Incorrecta");
      //this.setState({ ...this.state, show: false, mostrarMensaje: true, mensaje: "Email o Contraseña Incorrecta" });
    }

  }
  const handleRedirect = () => {
    const origin = query.get("origin");
    // console.log(origin);
    if (origin !== null) {
      redirecTo(origin, accessToken);
    } else {
      redirecTo("https://cydocs.cl");
    }

  }

  const handleBack = () => {
    const origin = this.query.get("origin") || "https://cydocs.cl";
    redirecTo(origin);
  }
  const handleGoogleLogin = async () => {
    // console.log(query.get("scope"))
    const scope = query.get("scope");
    // console.log(scope);
    let result = await loginGoogle(scope);
    setAccessToken(result.credential.accessToken)
    // console.log(result);
  }
  return (
    loading ? <Spinner /> :
      <div>
        <Route exact={true} path='/' render={() =>
          <NewHome
            handleLogout={handleLogout}
            usuarioAutenticado={usuario}
            handleOnSubmit={handleSubmit}
            handleChange={handleChange}
            handleRedirect={handleRedirect}
            handleBack={handleBack}
            loading={loading}
            handleGoogleLogin={handleGoogleLogin}>
          </NewHome>
        } />
      </div>
  );
}

/*
class App2 extends React.Component {
  state = {
    form: {
      email: '',
      password: '',
    },
    confirmar: false,
    mensaje: "",
    error: "",
    mostrarMensaje: false,
    loading: true,
    usuario: ""
  }

  handleSubmit = async event => {
    event.preventDefault();
    try {
      const { form } = this.state;
      await loginUsuario(form.email, form.password)
        .then(res => {

          const origin = query.get("origin");
          console.log(origin);
          // redirecTo(origin);
        })
        .catch((error) => {
          if (error.status === 401) {
            console.log("Invalid Login");
          } else {
            console.log(error);
          }
        });
      this.setState({ ...this.state, show: false, mostrarMensaje: true, mensaje: "Inició Sesión de manera correcta" });
    } catch (error) {
      console.log("imprimiendo error", error.message);
      this.setState({ ...this.state, show: false, mostrarMensaje: true, mensaje: "Email o Contraseña Incorrecta" });
    }

  }

  handleLogout = async () => {
    try {
      await cerrarSesion();
      this.setState({
        ...this.state,
        confirmar: false,
        usuario: ""
      });
    } catch (error) {
      console.log(error)
    }
  }

  handleChange = field => e => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: e.target.value,
      }
    })
  }

  handleRedirect = () => {
    const origin = this.getQueryVariable("origin");
    redirecTo(origin);
  }

  handleBack = () => {
    const origin = this.getQueryVariable("origin") || "https://cydocs.cl";
    redirecTo(origin);
  }

  getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }

  componentWillMount() {

  }

  componentWillMount = async () => {

  }

  componentDidMount() {


    let forceOutSession = this.getQueryVariable("force-out-sesion");

    auth.onAuthStateChanged(async user => {
      if (user) {
        console.log("force log con use 107", forceOutSession);
        if (!forceOutSession) {
          console.log("procedimeinto para forzar cierre 109");
          try {
            const usuario = auth.currentUser;
            await getSessionCookie();
            console.log("into trye 113");
            this.setState({ confirmar: true, usuario: usuario, loading: false });
          }
          catch (error) {
            console.log("error");
            this.setState({ confirmar: false });
            console.log(error);
          }
        } else {
          console.log("force de ")
          let origin = this.getQueryVariable("origin");
          let scope = this.getQueryVariable("scope");
          this.props.history.push('/?origin=' + origin + '&scope=' + scope)

          this.setState({ confirmar: false, loading: false });
          await cerrarSesion();
        }

      } else {
        await checkSession()
          .catch(function (error) {
            console.log(error);
          });
        this.setState({ confirmar: false, loading: false, usuario: "" });
      }
    });
  }

  componentWillUnmount() {
    this.authFirebaseListener && this.authFirebaseListener() // Unlisten it by calling it as a function

  }

  handleGoogleLogin = async () => {
    const scope = this.getQueryVariable("scope");
    console.log(scope);
    await loginGoogle(scope);
  }





  render() {
    const { loading, usuario } = this.state
    const { history } = this.props
    return (
      loading ? <Spinner /> :
        <div>
          <Route exact={true} path='/' render={() =>
            // <Home
            //   handleLogout={this.handleLogout}
            //   usuarioAutenticado={usuario}
            //   handleSubmit={this.handleSubmit}
            //   handleChange={this.handleChange}
            //   handleRedirect={this.handleRedirect}
            //   handleBack={this.handleBack}
            //   form={this.state.form}
            //   handleGoogleLogin={this.handleGoogleLogin}
            // ></Home>
            <NewHome
              handleLogout={this.handleLogout}
              usuarioAutenticado={usuario}
              handleOnSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              handleRedirect={this.handleRedirect}
              handleBack={this.handleBack}
              form={this.state.form}
              handleGoogleLogin={this.handleGoogleLogin}
            ></NewHome>
          } />
        </div>
    );

  }

}
*/
export default App;
