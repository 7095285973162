import React, { useState } from "react";
import { Formik } from "formik";
import { string } from "yup";
import {
  Button,
  Container,
  Grid,
  TextField,
  Box,
  makeStyles,
  withStyles,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import GoogleButton from "react-google-button";
import logo from "../img/logo.png";
import logoCydocs from "../img/cydocs_v2.png";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  "@global": {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    ".MuiOutlinedInput-root": {
      borderRadius: "25px",
    },
  },
})(() => null);

function Login(props) {
  const { handleOnSubmit, handleGoogleLogin, loading } = props;

  const [formularioExternoVisible, setFormularioExternoVisible] = useState(
    false
  );

  const values = {
    email: "",
    password: "",
  };

  const validation = Yup.object({
    email: Yup.string().email().required("El email es requerido."),
    password: Yup.string().required("El contaseña es requerido."),
  });

  return (
    <div>
      <GlobalCss />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid
            id="loginDos"
            container
            direction="column"
            justify="center"
            spacing={2}
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Box mt={5}>
                  <img src={logoCydocs} alt="img" width={200} />
                </Box>
              </Grid>
            </Grid>
            <Grid item style={{ width: "80%" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleGoogleLogin}
                style={{ borderRadius: "25px", padding: 10,textTransform: 'none' }}
                startIcon={<FontAwesomeIcon icon={["fab", "google"]} />}
                color="primary"
              >
                Acceso a Usuarios CyD
              </Button>
            </Grid>
            <Grid item style={{ width: "80%" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ borderRadius: "25px", padding: 10, color:"#8C8C8C" }}
                onClick={() => {
                  console.log("hola mndo ");
                  setFormularioExternoVisible(!formularioExternoVisible);
                }}
                
                
              >
                Acceso Externos
              </Button>
            </Grid>
            <Grid item style={{ width: "80%" }}>
              <Divider variant="middle" />
            </Grid>
          </Grid>
          {!formularioExternoVisible ? (
            <Container></Container>
          ) : (
            <Formik
              onSubmit={handleOnSubmit}
              initialValues={values}
              validationSchema={validation}
              //onChange={handleChange}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,

                isValid,
                /* and other goodies */
              }) => (
                <Container maxWidth="xl">
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Typography
                      align="center"
                      variant="body2"
                      gutterBottom
                      style={{
                        opacity: 0.7,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      Ingresa tu correo electrónico y la contraseña
                      proporcionada por el equipo CyDocs
                    </Typography>
                  </Grid>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      style={{ paddingTop: 0 }}
                      justify="center"
                      spacing={2}
                      alignItems="center"
                    >
                      <Grid item style={{ width: "80%" }}>
                        <TextField
                          name="email"
                          style={{
                            visibility: formularioExternoVisible
                              ? "visible"
                              : "hidden",
                          }}
                          label="Correo electronico"
                          style={{ borderRadius: 25 }}
                          value={values.email}
                          variant="outlined"
                          fullWidth
                          required
                          error={
                            touched.email &&
                            errors.email &&
                            Boolean(errors.email)
                          }
                          helperText={
                            touched.email && errors.email ? errors.email : ""
                          }
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item style={{ width: "80%" }}>
                        <TextField
                          name="password"
                          label="Contraseña"
                          value={values.password}
                          variant="outlined"
                          type="password"
                          fullWidth
                          required
                          error={
                            touched.password &&
                            errors.password &&
                            Boolean(errors.password)
                          }
                          helperText={
                            touched.password && errors.password
                              ? errors.password
                              : ""
                          }
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item style={{ width: "80%" }}>
                        <Button
                          type="submit"
                          fullWidth
                          disabled={!isValid}
                          variant="contained"
                          style={{ borderRadius: "25px", padding: 10 }}
                          color="#8C8C8C"
                        >
                          Iniciar Sesión
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Container>
              )}
            </Formik>
          )}
          <Grid
            container
            direction="column"
            style={{ paddingTop: 0 }}
            justify="center"
            spacing={2}
            alignItems="center"
          >
            <Grid item style={{ width: "80%" }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Box mt={5}>
                    <img src={logo} alt="img" width={120} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

export default Login;
