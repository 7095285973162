import { auth } from './firebase.js';
import fetch from 'node-fetch';
import  firebase from 'firebase';

const googleProvider = new firebase.auth.GoogleAuthProvider();

// const baseUrl = 'http://localhost:5001/cydingenieria-proyectos/us-central1/widgets';
// const baseUrl = 'https://us-central1-cydingenieria-proyectos.cloudfunctions.net/widgets';
// const baseUrl = 'https://api-usuarios.cydocs.cl/login';

const getBaseUrl = () => {
  console.log("getBaseUrl", window.location.hostname)
  if (window.location.hostname.endsWith('micyd.cl')) {
    return 'https://api-usuarios.micyd.cl/login';
  } else {
    return 'https://api-usuarios.cydocs.cl/login';
  }
}

const getQueryVariable = function (variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === variable) { return pair[1]; }
  }
  return (false);
}

export const loginUsuario = async (email, password) => {
  var token = "";
  return auth.signInWithEmailAndPassword(email, password);
}

export const loginGoogle = async (scopes) => {
  if (scopes) {
    let scopeSplit = scopes?.split(",");
    scopeSplit.forEach(scope => {
      googleProvider.addScope(scope);
    });
  }
  return firebase.auth().signInWithPopup(googleProvider);
}

export const checkSession = async () => {
  const csrfToken = getQueryVariable("csrfToken") || localStorage.getItem("csrfToken") || "";
  localStorage.setItem("csrfToken", csrfToken);
  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/verifySessionCookie?_csrf=${csrfToken}`, {
    method: 'post',
    credentials: 'include',
  })
    .then(res => {
      if (res.status === 200) {
        return res.json()
      } else {
        return null;
      }
    })
    .then((res) => {
      if (res) {
        return auth.signInWithCustomToken(res.token);
      } else {
        return null;
      }
    });
}

export const cerrarSesion = async () => {
  var token = "";
  localStorage.setItem("csrfToken", "");
  const baseUrl = getBaseUrl();
  return auth.currentUser.getIdToken(true)
    .then((id_token) => {
      token = id_token;
      return fetch(`${baseUrl}/getCSRFToken`, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
    })
    .then(res => res.json())
    .then((res) => {
      fetch(`${baseUrl}/sessionLogout`, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${res.csrfToken}`
        },
      });
      // return axios.get(`${baseUrl}/sessionLogout`, {
      //   withCredentials: true,
      //   crossDomain: true,
      //   headers: {
      //     'Authorization': `Bearer ${res.csrfToken}`
      //   }
      // });
    })
    .then((res) => auth.signOut());

}

export const redirecTo = async (destiny, accessToken) => {
  const baseUrl = getBaseUrl();
  var url = `${baseUrl}/redirectToApp`;
  if (destiny) {
    url += `?redirect-url=${destiny}&accessToken=${accessToken}`;
  }
  window.location.href = url;
}

export const getSessionCookie = async () => {
  const baseUrl = getBaseUrl();
  var token = "";
  return auth.currentUser.getIdToken(true).then((id_token) => {
    token = id_token;
    return fetch(`${baseUrl}/getCSRFToken`, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
  })
    .then(res => res.json())
    .then((res) => {
      fetch(`${baseUrl}/sessionLogin/?_csrf=${res.csrfToken}`, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      // return axios.get(`${baseUrl}/sessionLogin/`, {
      //   withCredentials: true,
      //   crossDomain: true,
      //   xsrfCookieName: 'csrfToken',
      //   xsrfHeaderName: 'CSRF-Token',
      //   headers: {
      //     'Authorization': `Bearer ${token}`,
      //     'CSRF-Token': res.csrfToken
      //   }
      // })
    });
}
