import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

const history = createBrowserHistory();
const theme = createMuiTheme({
    typography: {
        fontSize: 14,
        htmlFontSize: 17,
        fontFamily: ['Poppins', 'sans-serif']

    },
    palette: {
        primary: {
            light: '#a64c46',
            main: '#731f1f',
            dark: '#430000',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#4f5b62',
            main: '#263238',
            dark: '#000a12',
            contrastText: '#ffffff',
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
        },
    },
});


ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
            <Router history={history}>
                <App history={history} />
            </Router>
        </SnackbarProvider>
    </MuiThemeProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
