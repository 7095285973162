import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import logo from '../img/logo.png';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ArrowForward from '@material-ui/icons/ArrowForward';

export default function SignInSide(props) {
  const { handleLogout, usuarioAutenticado, handleRedirect, classes } = props;

  return (
    <div className={classes.center} style={{paddingTop:20}}>
      <div className={classes.paper}>
        <div>
          <Avatar alt="RA" src={usuarioAutenticado.photoURL} className={classes.avatar} />
        </div>
        <br />
        <Typography component="h1" variant="h5">
          Bienvenido, {usuarioAutenticado.displayName}
        </Typography>
        <br />
        <Typography>{usuarioAutenticado.email}</Typography>
        <br />
      </div>

      <Button
        className={classes.button}
        variant='outlined'
        color='default'
        style={{ borderRadius: 25 }}
        onClick={handleLogout}><ExitToApp /> Cerrar Sesión</Button>
      <Button className={classes.button}
        style={{ borderRadius: 25 }}
        variant='contained' 
        color='primary' 
        onClick={handleRedirect}><ArrowForward /> Continuar</Button>

      <div className={classes.paper}>
        <Box className={classes.paper} mt={5}>
          <img src={logo} alt="img" width={100}/>
        </Box>
      </div>
    </div>
  );

}