import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import building from "../img/building.jpg";
// import Login from "./Login";
// import Logout from "./Logout";

import Login from "./LoginDos";
import Logout from "./Logout";

import videosrc from "../img/cyd_video_3.mp4";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { motion } from "framer-motion";

const useStyles2 = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  center: {
    textAlign: "center",
  },
  image: {
    backgroundImage: `url(${building})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  button: {
    margin: 10,
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  google: {
    backgroundColor: "green",
  },
  avatar: {
    width: 80,
    height: 80,
  },
}));

const style = {
  google: {
    width: "100%",
  },
};

export default function SignInSide(props) {
  const classes2 = useStyles2();
  const {
    handleLogout,
    loading,
    handleOnSubmit,
    handleChange,
    form,
    usuarioAutenticado,
    handleRedirect,
    handleGoogleLogin,
    handleBack,
  } = props;

  const matches = useMediaQuery("(min-width:600px)");
  console.log(matches);

  return (
    <Grid container component="main" className={classes2.root}>
      <CssBaseline />
      <Grid item xs={false} sm={12} md={12}>
        <video
          autoPlay
          loop
          muted
          style={{
            position: "fixed",
            right: 0,
            bottom: 0,
            minWidth: "100%",
            minHeight: "100%",
          }}
        >
          <source src={videosrc} type="video/mp4" />
        </video>
        <div
          style={{
            position: "fixed",
            right: 0,
            bottom: 0,
            minWidth: "100vw",
            minHeight: "100vh",
            background: !matches ? "#fff" : "#ffffff80",
          }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              container
              md={6}
              sm={6}
              xs={12}
              id="contenedor-grander"
              justify="center"
            >
              <Grid
                item
                md={12}
                md={8}
                sm={12}
                xs={12}
                style={{ marginTop: "10%" }}
              >
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: {
                      scale: 0.8,
                      opacity: 0,
                    },
                    visible: {
                      scale: 1,
                      opacity: 1,
                      transition: {
                        delay: 0.5,
                      },
                    },
                  }}
                >
                  <Paper
                    square={true}
                    style={{ borderRadius: 20, width: "100%" }}
                  >
                    {props.usuarioAutenticado ? (
                      <Logout
                        handleLogout={handleLogout}
                        usuarioAutenticado={usuarioAutenticado}
                        handleRedirect={handleRedirect}
                        classes={classes2}
                      ></Logout>
                    ) : (
                      <Login
                        loading={loading}
                        handleOnSubmit={handleOnSubmit}
                        handleChange={handleChange}
                        form={form}
                        classes={classes2}
                        handleGoogleLogin={handleGoogleLogin}
                        handleBack={handleBack}
                        style={{ width: "100%" }}
                      ></Login>
                    )}
                  </Paper>
                </motion.div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>

      {/* <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {props.usuarioAutenticado ? (
          <Logout
            handleLogout={handleLogout}
            usuarioAutenticado={usuarioAutenticado}
            handleRedirect={handleRedirect}
            classes={classes2}
          ></Logout>
        ) : (
          <Login
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            form={form}
            classes={classes2}
            handleGoogleLogin={handleGoogleLogin}
            handleBack={handleBack}
          ></Login>
        )}
      </Grid> */}
    </Grid>
  );
}
