import  firebase from 'firebase'

const config = {
  apiKey: "AIzaSyDiRfYh144WXcsui3Ok_DfF-rnm84OgbTk",
  authDomain: "auth.cydocs.cl",
  databaseURL: "https://cydingenieria-proyectos.firebaseio.com",
  projectId: "cydingenieria-proyectos",
  storageBucket: "cydingenieria-proyectos.appspot.com",
  messagingSenderId: "908645375548",
  appId: "1:908645375548:web:8542c66bc42fb1bb"
};

firebase.initializeApp(config);

export const auth = firebase.auth()
export const db = firebase.firestore()
export const storage = firebase.storage()
